import { PagedRequest } from '@domain/services/paged-request';
import { Observable } from 'rxjs';
import { ListUserQuery, ListUserResponse } from './list-user.dto';
import { GetUserQuery, GetUserResponse } from './get-user.dto';
import { ShowUserResponse } from './show-user.dto';
import { ChangeStatusPayload, WriteUserPayload, WriteUserResponse } from './write-user.dto';

export abstract class UserRepository implements PagedRequest {
    abstract show(id: number): Observable<ShowUserResponse>;

    abstract get(query?: GetUserQuery): Observable<GetUserResponse>;

    abstract list(query?: ListUserQuery): Observable<ListUserResponse>;

    abstract create(payload:WriteUserPayload): Observable<WriteUserResponse>;

    abstract update(payload:WriteUserPayload): Observable<WriteUserResponse>;

    abstract changeStatus(payload:ChangeStatusPayload): Observable<WriteUserResponse>;
}
